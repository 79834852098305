import { isImmutable, Map } from 'immutable';
import React from 'react';
import { Icon, Image, Segment } from 'semantic-ui-react';

import { Features } from '../../../Constants';
import styles from './styles.module.scss';

export default function LeftMenu(props) {
    const { user = Map(), version = '', onClick = () => {} } = props;

    const [collapse, setCollapse] = React.useState([]);

    const handleItemClick = (evt, values) => {
        const { key, children } = values;
        if (children) {
            setCollapse(collapse.includes(key) ? collapse.filter(v => v !== key) : collapse.concat(key));
        } else {
            onClick(evt, values);
        }
    };

    // 156975

    const Item = props => {
        const { item, is_child, onItemClick = () => {} } = props;
        const { key, children } = item;

        const isCollapsed = collapse.includes(key);

        return (
            <React.Fragment>
                <div className={'item ' + (is_child && ' child')} onClick={evt => onItemClick(evt, item)}>
                    {item.icon && <Icon name={item.icon} />}
                    <span style={{ flex: 1 }}>{item.content}</span>
                    {!!children && <Icon name={isCollapsed ? 'caret down' : 'caret left'} />}
                </div>
                {!!children && !isCollapsed && (
                    <div className="child_container">
                        {children.map(children => (
                            <Item item={children} key={children.key} is_child onItemClick={onItemClick} />
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const admin = user.get('admin') ?? false;
    const permission = isImmutable(user.get('permission')) ? user.get('permission').toJS() : false;

    const _features = Features.filter(f => (admin ? f.admin : !f.admin)).filter(f => {
        if (permission instanceof Array) {
            return permission.includes(f.key);
        } else {
            return true;
        }
    });

    return (
        <Segment inverted attached className={styles.container}>
            <div className="title">
                <Image src="/logo.png" size="mini" />
                <h2>学车宝</h2>
            </div>
            <div className="content">
                {_features.map(feature => (
                    <Item item={feature} key={feature.key} onItemClick={handleItemClick} />
                ))}
            </div>
            <div className="footer">{version}</div>
        </Segment>
    );
}
